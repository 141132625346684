const AUTH_KEY = 'auth';
let localStorage;

export const init = () => {
    localStorage = window.localStorage;
};

export const isLoggedIn = async () => {
    const auth = localStorage.getItem(AUTH_KEY);
    if (auth === null) {
        return false;
    }
    else {
        return true;
    }
};

export const login = async () => {
    const search = window.location.search;
    const urlParams = new URLSearchParams(search);
    const jwt = urlParams.get('jwt');

    if (jwt) {
        window.localStorage.setItem(AUTH_KEY, jwt);
        window.location.assign('/');
    }
    else {
        window.location.assign('/api/auth/login');
    }
};

export const fetchWithAuth = async (input, init) => {
    const headers = authHeaders();
    init = { ...init, headers };
    const response = await fetch(input, init);

    if (!response.ok) {
        if (response.status === 401) {
            logout();
        }

        const error = response.statusText;
        return error;
    }

    return response;
};

export function logout() {
    localStorage.removeItem(AUTH_KEY);
    window.location.assign('/api/auth/logout');
}

export function authHeaders() {
    const access_token = localStorage.getItem(AUTH_KEY);

    if (access_token) {
        return { Authorization: 'Bearer ' + access_token };
    }
    else {
        return {};
    }
}
