import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actionCreators from '../../actions';
import { bindActionCreators } from 'redux';
import spinnerLogo from '../../spinner-logo.png';
import FileStatusList from './FileStatusList';
import './StatusDisplay.css';

class StatusDisplay extends Component {
    statusesObjectToList(statuses) {
        return Object.keys(statuses).map((key) => {
            const statusObj = statuses[key];
            return { ...statusObj, id: key };
        });
    }

    componentDidMount() {
        const { fetchStatus, fetchStatuses, setPolling } = this.props.actions;
        const { polling } = this.props;
        const poll = () => {
            setTimeout(async () => {
                const { statuses } = this.props;
                const statusesList = this.statusesObjectToList(statuses);
                for (const status of statusesList) {
                    if (!(status.status === 'complete' || status.status === 'rejected'))  {
                        await fetchStatus(status.id);
                    }
                }
                poll();
            }, 2000);
        };
        if (!polling) {
            setPolling(true);
            fetchStatuses();
            poll();
        }
    }

    render() {
        const { loading, statuses } = this.props;
        const statusesList = this.statusesObjectToList(statuses);
        return (<div className="status-display">
            { loading ?
                <img src={spinnerLogo} className="spinner-logo spinner" alt="logo" /> :
                <div>
                    <div className="file-list-header">
                        Upload History { (statusesList.length > 0) && <span id="upload-count">({statusesList.length} uploaded)</span> }
                    </div>
                    <div className="divider"/>
                    {
                        statusesList.length > 0 ?
                            <FileStatusList statuses={statusesList} /> :
                            <div className="file-list-empty-text">
                                No files have been imported
                            </div>
                    }
                </div>
            }
        </div>);
    }
}

const mapStateToProps = (state) => ({
    statuses: state.status.statuses,
    loading: state.status.loading,
    polling: state.status.polling
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

StatusDisplay.propTypes = {
    statuses: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    polling: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
        fetchStatuses: PropTypes.func.isRequired,
        fetchStatus: PropTypes.func.isRequired,
        setPolling: PropTypes.func.isRequired
    })
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusDisplay);