import {
    SET_USER_INFO
} from '../constants/ActionTypes';

const initialState = {
    username: undefined
};

export default function status(state = initialState, action) {
    switch (action.type) {
        case SET_USER_INFO:
            return { ...state, ...action.userInfo };
        default:
            return state;
    }
}