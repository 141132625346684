import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../actions';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import UploadProgressBar from './UploadProgressBar';
import FileUploadWrapper from '../../components/FileUploadWrapper';
import './FileConfirmationPanel.css';

class FileConfirmationPanel extends Component {
    render() {
        const { selectedFile, selectedFiles, uploading, progress, uploadingFileIndex, bulkUpload, confirmationMessage, confirmationStatus } = this.props;
        const { resetFileSelection, selectFile, selectFiles, uploadFile } = this.props.actions;
        const uploadReady = (selectedFile || selectedFiles.length > 0);
        const cancelButtonActive = (!uploading || confirmationStatus === 'error');
        const fileNameElements = selectedFiles.map((file, idx) => {
            if (!uploading || (idx >= (uploadingFileIndex + 1))) {
                return <div key={file.name + idx} className='status-filename' title={ file.name }>
                    { file.name }
                </div>;
            }
            else {
                return null;
            }
        });
        return (<div className={'file-confirmation-panel'}>
            <div className="file-confirmation-body">
                <div className="step-text"> Step 2: Upload your file. </div>
                <div className="divider"/>
                <div className='file-confirmation-workarea' onClick={ () => {
                    if (confirmationStatus === 'error') {
                        resetFileSelection();
                    }
                }}>
                    <div className='file-upload-message'>
                        {
                            uploadReady ?
                                selectedFile ? <div className='selected-file-confirmation'>
                                    <i className={ confirmationStatus === 'error' ? 'icon-cancel_circle_outline_24 cancel-icon' : 'icon-survey_24 survey-icon'} />
                                    <div>
                                        <div className='upload-name'>{selectedFile.name}</div>
                                        {
                                            uploading ?
                                                <div>
                                                    <div>
                                                        { confirmationMessage ? ('Error - ' + confirmationMessage) : 'uploading'}
                                                    </div>
                                                    <UploadProgressBar progress={progress} status={confirmationStatus} />
                                                </div> :
                                                <div>
                                                    { confirmationMessage ? confirmationMessage : 'Your file is ready to upload'}
                                                </div>
                                        }
                                    </div>
                                </div> :
                                    <div>
                                        <div className='selected-files-names'>
                                            { fileNameElements }
                                        </div>
                                        Are ready for upload
                                    </div> :
                                <FileUploadWrapper selectFile={selectFile} selectFiles={selectFiles} bulkUpload={bulkUpload}>
                                    <div className='file-upload-drag-message'>
                                        Drag and drop your file anywhere on the page
                                    </div>
                                    <div className='file-upload-browse-message'>
                                        or <span className='browse'> browse </span> to select a file
                                    </div>
                                </FileUploadWrapper>
                        }
                        { uploading && selectedFiles && (selectedFiles.length  > uploadingFileIndex + 1) && <div>
                            { selectedFiles.length - uploadingFileIndex - 1} File{((selectedFiles.length - uploadingFileIndex - 1) !== 1) && 's'} remaining!
                            <div className='selected-files-names'>
                                { fileNameElements }
                            </div>
                        </div>
                        }
                    </div>
                </div>
                <div className="file-confirmation-buttons">
                    { uploadReady ?
                        <div className={'abort-button' + (cancelButtonActive ? ' active' : ' disabled')}
                            onClick={ () => {
                                if (cancelButtonActive) {
                                    resetFileSelection();
                                }
                            }}>
                        Cancel
                        </div>    :
                        <div/>
                    }
                    <div className={'import-button' + ((uploadReady && !uploading) ? ' active' : ' disabled')}
                        onClick={ () => {
                            if (uploadReady && !uploading) {
                                uploadFile();
                            }
                        }}>
                        Import
                    </div>
                </div>
            </div>
        </div>);
    }
}

const mapStateToProps = (state) => ({
    selectedType: state.fileUpload.selectedType,
    selectedFile: state.fileUpload.selectedFile,
    selectedFiles: state.fileUpload.selectedFiles,
    progress: state.fileUpload.progress,
    uploading: state.fileUpload.uploading,
    confirmationMessage: state.fileUpload.confirmationMessage,
    confirmationStatus: state.fileUpload.confirmationStatus,
    bulkUpload: false,
    uploadingFileIndex: state.fileUpload.uploadingFileIndex
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

FileConfirmationPanel.propTypes = {
    selectedType: PropTypes.object,
    selectedFile: PropTypes.shape({
        name: PropTypes.string.isRequired
    }),
    selectedFiles: PropTypes.array,
    progress: PropTypes.number.isRequired,
    uploading: PropTypes.bool.isRequired,
    bulkUpload: PropTypes.bool,
    uploadingFileIndex: PropTypes.number,
    confirmationMessage: PropTypes.string,
    confirmationStatus: PropTypes.string,
    actions: PropTypes.shape({
        resetFileSelection: PropTypes.func.isRequired,
        selectFile: PropTypes.func.isRequired,
        selectFiles: PropTypes.func.isRequired,
        uploadFile: PropTypes.func.isRequired
    })
};

export default connect(mapStateToProps, mapDispatchToProps)(FileConfirmationPanel);