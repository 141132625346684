import React, { Component } from 'react';
import FileStatusFormatter from './FileStatusFormatter';
import moment from 'moment';
import PropTypes from 'prop-types';

class FileStatusList extends Component {
    constructor(props) {
        super(props);
        this.listRef = React.createRef();
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if (prevProps.statuses.length < this.props.statuses.length) {
            const list = this.listRef.current;
            return (list.scrollTop + list.offsetHeight === list.scrollHeight);
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshotShouldScrollBottom) {
        if (snapshotShouldScrollBottom) {
            const list = this.listRef.current;
            list.scrollTop = list.scrollHeight;
        }
    }

    render() {
        const { statuses } = this.props;
        const statusElements = statuses.sort((statusA, statusB) => {
            return moment(statusB.uploadDateTime).diff(moment(statusA.uploadDateTime));
        }).map((status) => {
            return <FileStatusFormatter key={status.id} status={status}/>;
        });
        return (<div className='file-list-statuses' ref={this.listRef}>
            { statusElements }
        </div>);
    }
}

FileStatusList.propTypes = {
    statuses: PropTypes.array.isRequired
};

export default FileStatusList;