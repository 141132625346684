import React, { Component } from 'react';
import FileUploadDialog from './containers/FileUploadDialog';
import TopBar from './containers/TopBar';

class Home extends Component {
    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <TopBar />
                    <i className="logo" />
                    <div className="welcome">
                        Welcome
                    </div>
                    <div className="organization-name">
                        Starbucks
                    </div>
                    <div className="main-message">
                        Let&#39;s start uploading your files
                    </div>
                </header>
                <FileUploadDialog />
            </div>
        );
    }
}

export default Home;
