import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../actions';
import { bindActionCreators } from 'redux';
import MessageSection from './MessageSection';
import DataTypeSelector from '../DataTypeSelector';
import FileConfirmationPanel from '../FileConfirmationPanel';
import StatusDisplay from '../StatusDisplay';
import './FileUploadDialog.css';

class FileUploadDialog extends Component {
    render() {
        return (<div className="file-upload-dialog">
            <div className="upload-section">
                <MessageSection showUpgradeMessage={false} />
                <DataTypeSelector />
                <FileConfirmationPanel />
            </div>
            <div className="status-display-section">
                <StatusDisplay />
            </div>
        </div>);
    }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadDialog);