import {
    ADD_STATUS,
    ADD_STATUSES,
    UPDATE_STATUS,
    LOAD_STATUS_START,
    LOAD_STATUS_END,
    SET_POLLING
} from '../constants/ActionTypes';

const initialState = {
    loading: false,
    statuses: {},
    polling: false
};

export default function status(state = initialState, action) {
    switch (action.type) {
        case ADD_STATUS:
            return { ...state, statuses: {...state.statuses,  [action.status.id]: action.status } };
        case ADD_STATUSES:
            return {...state, statuses: {...state.statuses, ...action.statuses}};
        case UPDATE_STATUS:
            return {...state, statuses: {...state.statuses, [action.updatedStatus.id]: action.updatedStatus }};
        case LOAD_STATUS_START:
            return {...state, loading: true};
        case LOAD_STATUS_END:
            return {...state, loading: false};
        case SET_POLLING:
            return {...state, polling: action.polling };
        default:
            return state;
    }
}