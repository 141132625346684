import { SELECT_TYPE, SELECT_FILE,
    SELECT_FILES,
    CANCEL_FILE_SELECTION,
    RESET_FILE_SELECTION,
    UPLOAD_FILE,
    UPLOAD_PROGRESS,
    UPLOAD_START,
    UPLOAD_END,
    UPDATE_UPLOADING_FILE_INDEX,
    UPDATE_FILE_CONFIRMATION_MESSAGE
} from '../constants/ActionTypes';

const initialState = {
    selectedType: null,
    selectedFile: null,
    selectedFiles: [],
    uploadingFileIndex: 0,
    progress: 0,
    uploading: false,
    confirmationStatus: null,
    confirmationMessage: ''
};

export default function fileUpload(state = initialState, action) {
    switch (action.type) {
        case SELECT_TYPE:
            return { ...state, selectedType: action.selectedType };
        case SELECT_FILE:
            return { ...state, selectedFile: action.selectedFile };
        case SELECT_FILES:
            return { ...state, selectedFiles: action.selectedFiles };
        case RESET_FILE_SELECTION:
            return { ...initialState, selectedType: state.selectedType };
        case CANCEL_FILE_SELECTION:
            return { ...initialState, selectedType: state.selectedType };
        case UPLOAD_FILE:
            return { ...initialState, selectedType: state.selectedType };
        case UPLOAD_PROGRESS:
            return { ...state, progress: action.progress };
        case UPLOAD_START:
            return { ...state, uploading: true };
        case UPLOAD_END:
            return { ...state, selectedFile: null, uploading: false, progress: 0 };
        case UPDATE_UPLOADING_FILE_INDEX:
            return { ...state, uploadingFileIndex: action.uploadingFileIndex };
        case UPDATE_FILE_CONFIRMATION_MESSAGE:
            return { ...state, confirmationStatus: action.status, confirmationMessage: action.message };
        default:
            return state;
    }
}