/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select, { components } from 'react-select';
import * as actionCreators from '../../actions';
import { bindActionCreators } from 'redux';
import './DataTypeSelector.css';

const Option = (props) => {
    return (
        <components.Option className='custom-option' {...props}>
            <i className={props.data.faClass + ' datatype-icon'} /> { props.data.label } file
        </components.Option>
    );
};

const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
        <i className={props.data.faClass + ' datatype-icon'} /> { children } file
    </components.SingleValue>
);

const customStyles = {
    control: () => ({
        width: 300,
        height: 36,
        backgroundColor: 'transparent',
        borderRadius: '24.5px',
        border: 'solid 1px #253746',
        color: '#253746',
        display: 'flex'
    }),
    placeholder: () => ({
        color: '#253746',
        paddingLeft: '16px',
        fontFamily: 'IBM Plex Sans',
        fontSize: '1.25rem'
    }),
    dropdownIndicator: () => ({
        color: '#253746',
        padding: '0 10px',
        cursor: 'pointer'
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    singleValue: () => ({
        fontFamily: 'IBM Plex Sans',
        letterSpacing: '0.1px',
        paddingLeft: '5px',
        fontSize: '0.875rem',
        color: '#253746'
    }),
    menu: (base) => ({
        ...base,
        width: 300
    }),
    option: () => ({
        fontFamily: 'IBM Plex Sans',
        letterSpacing: '0.1px',
        fontSize: '0.875rem',
        color: '#253746',
        cursor: 'pointer',
        height: '30px',
        paddingTop: '0.625rem',
        paddingLeft: '1rem',
        borderBottom: 'solid 1px #eae9e9',
        '&:hover': {
            color: '#0058ff',
            backgroundColor: '#e9f0ff'
        },
        '&:last-child': {
            borderBottom: 'none'
        }
    })
};

class DataTypeSelector extends Component {
    render() {
        const { additionalSource, selectedType } = this.props;
        const { selectType } = this.props.actions;
        //May be configurable in the future.
        const fileTypesToDisplay = ['hierarchy', 'transaction'];

        let dataTypes = [{
            value: 'email',
            label: 'Emails',
            faClass: 'fas fa-envelope'
        },
        {
            value: 'phone',
            label: 'Phone Logs',
            faClass: 'fas fa-phone'
        },
        {
            value: 'yelp',
            label: 'Yelp Reviews',
            faClass: 'fab fa-yelp'
        },
        {
            value: 'hierarchy',
            label: 'Hierarchy',
            faClass: 'fas fa-sitemap'
        },
        {
            value: 'transaction',
            label: 'Print Interval',
            faClass: 'fas fa-dollar-sign'
        }];

        if (additionalSource) {
            dataTypes = [...dataTypes, {
                value: 'web',
                label: 'Web',
                faClass: 'fas fa-globe'
            }];
        }

        dataTypes = dataTypes.filter((dataType) => {
            return fileTypesToDisplay.indexOf(dataType.value) !== -1;
        });
        return (<div className="data-type-selector">
            <div className="data-type-selector-title">
                Select a data source
            </div>
            <Select
                isSearchable={false}
                styles={customStyles}
                className="data-type-selectbox"
                components={{ Option, SingleValue }}
                onChange={selectType}
                options={dataTypes}
                placeholder={'Select'}
                value={selectedType}
            />
        </div>);
    }
}

const mapStateToProps = (state) => ({
    selectedType: state.fileUpload.selectedType
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

DataTypeSelector.propTypes = {
    selectedType: PropTypes.object,
    selectedFile: PropTypes.object,
    additionalSource: PropTypes.bool,
    actions: PropTypes.shape({
        selectType: PropTypes.func.isRequired
    })
};

export default connect(mapStateToProps, mapDispatchToProps)(DataTypeSelector);