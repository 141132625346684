import React, { Component } from 'react';
import spinnerLogo from './images/spinner-logo.png';
import FullScreenDropzone from './containers/FullScreenDropzone';
import Home from './Home';
import { isLoggedIn, login } from './services/auth';
import './App.css';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authenticated: false
        };
    }

    async UNSAFE_componentWillMount() {
        try {
            if (window.location.pathname.indexOf('/auth/authorized') > -1) {
                return;
            }
            const authenticated = await isLoggedIn();
            if (!authenticated) {
                await login();
                return;
            }
            else {
                this.setState({
                    authenticated
                });
            }
        }
        catch (err) {
            console.error('Error checking if user logged in', err);
            await login();
            return;
        }
    }

    render() {
        const { authenticated } = this.state;

        return (
            <FullScreenDropzone>
                {
                    authenticated ?
                        <Home/> :
                        <div className="loading-mask">
                            <img src={spinnerLogo} className="spinner-logo spinner" alt="logo" />
                        </div>
                }
            </FullScreenDropzone>
        );
    }
}

export default App;