import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './components.css';

class FileUploadWrapper extends Component {
    fileChangedHandler(event) {
        const { bulkUpload } = this.props;
        if (bulkUpload && event.target.files.length > 1) {
            const selectedFiles = Array.from(event.target.files);
            this.props.selectFiles(selectedFiles);
        }
        else {
            this.props.selectFile(event.target.files[0]);
        }
    }

    render() {
        const { bulkUpload } = this.props;
        return (
            <div className='upload-button-wrapper'>
                { this.props.children }
                <input type='file' name={'upload-file-container'} onChange={this.fileChangedHandler.bind(this)} multiple={ bulkUpload } />
            </div>
        );
    }
}

FileUploadWrapper.propTypes = {
    bulkUpload: PropTypes.bool,
    selectFile: PropTypes.func.isRequired,
    selectFiles: PropTypes.func.isRequired,
    children: PropTypes.any
};

export default FileUploadWrapper;