export const SELECT_TYPE = 'SELECT_TYPE';
export const SELECT_FILE = 'SELECT_FILE';
export const SELECT_FILES = 'SELECT_FILES';
export const CANCEL_FILE_SELECTION = 'CANCEL_FILE_SELECTION';
export const RESET_FILE_SELECTION = 'RESET_FILE_SELECTION';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const ADD_STATUS = 'ADD_STATUS';
export const ADD_STATUSES = 'ADD_STATUSES';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const UPLOAD_START = 'UPLOAD_START';
export const UPLOAD_END = 'UPLOAD_END';
export const LOAD_STATUS_START = 'LOAD_STATUS_START';
export const LOAD_STATUS_END = 'LOAD_STATUS_END';
export const UPDATE_UPLOADING_FILE_INDEX = 'UPDATE_UPLOADING_FILE_INDEX';
export const SET_POLLING = 'SET_POLLING';
export const UPDATE_FILE_CONFIRMATION_MESSAGE = 'UPDATE_FILE_CONFIRMATION_MESSAGE';

export const UPDATE_FEATURE_FLAG = 'UPDATE_FEATURE_FLAG';
export const SET_USER_INFO = 'SET_USER_INFO';