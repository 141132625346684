import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class FileStatusFormatter extends Component {
    download(id) {
        const accessToken = localStorage.getItem('auth');
        window.location.assign(`/api/upload/download/${id}?jwt=${accessToken}`);
    }

    render() {
        let statusIconCls = null;
        const { id, status, dataType, objectName, uploadDateTime } = this.props.status;
        if (status === 'uploading') {
            statusIconCls = 'fas fa-cloud-upload-alt';
        }
        else if (status === 'pending') {
            statusIconCls = 'far fa-clock';
        }
        else if (status === 'validated') {
            statusIconCls = 'fas fa-check-circle';
        }
        else if (status === 'complete') {
            statusIconCls = 'far fa-check-circle';
        }
        else if (status === 'rejected') {
            statusIconCls = 'fas fa-exclamation-triangle';
        }
        let typeIconCls = null;
        if (dataType === 'yelp') {
            typeIconCls = 'fab fa-yelp';
        }
        else if (dataType === 'email') {
            typeIconCls = 'fas fa-envelope';
        }
        else if (dataType === 'phone') {
            typeIconCls = 'fas fa-phone';
        }
        else if (dataType === 'hierarchy') {
            typeIconCls = 'fas fa-sitemap';
        }
        else if (dataType === 'transaction') {
            typeIconCls = 'fas fa-dollar-sign';
        }
        const dateTimeString = moment(uploadDateTime).format('MM/DD/YYYY LTS');
        return (<div className='formatted-status' onClick={() => { this.download(id);}}>
            <i className={statusIconCls + ' status-icon ' + status} title={status} />
            <div className='status-filename' title={ objectName }>
                { objectName }
            </div>
            <div className='status-uploaddatetime'>
                { dateTimeString }
            </div>
            <i className={typeIconCls + ' type-icon'} />
        </div>);
    }
}

FileStatusFormatter.propTypes = {
    status: PropTypes.object.isRequired
};

export default FileStatusFormatter;