import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './FileConfirmationPanel.css';

class UploadProgressBar extends Component {
    render() {
        const { progress, status } = this.props;
        return (<div>
            <div className='progressbar'>
                <div className={'progress' + (status ? ` ${status}` : '')} style={{ width: `${progress}%`}}></div>
            </div>
        </div>);
    }
}

UploadProgressBar.propTypes = {
    progress: PropTypes.number.isRequired,
    status: PropTypes.string
};

export default UploadProgressBar;