import React, { Component } from 'react';
import PropTypes from 'prop-types';

class MessageSection extends Component {
    render() {
        return (<div className="message-section">
            <div className="step-text"> Step 1: Select your data type to get started. </div>
            <div className="divider" />
        </div>);
    }
}

MessageSection.propTypes = {
    showUpgradeMessage: PropTypes.bool
};

export default MessageSection;