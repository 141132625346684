import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as actionCreators from '../../actions';
import { logout } from '../../services/auth';
import './TopBar.css';

class TopBar extends Component {
    state = {
        anchorEl: null
    };
    
    handleClick = event => {
      this.setState({ anchorEl: event.currentTarget });
    };
    
    handleClose = () => {
      this.setState({ anchorEl: null });
    };

    componentDidMount() {
        this.props.actions.fetchUserInfo();
    }

    render() {
        const { anchorEl } = this.state;
        const { username } = this.props;
        return (username ? <div className="top-bar">
            <Button
                aria-owns={anchorEl ? 'user-menu' : undefined}
                aria-haspopup='true'
                onClick={this.handleClick}>
                { username } <i className={'fas fa-chevron-down'} />
            </Button>
            <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'middle'}}
                getContentAnchorEl={null} 
            >
                <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
        </div> : <div/>);
    }
}

const mapStateToProps = (state) => ({
    username: state.user.username
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

TopBar.propTypes = {
    username: PropTypes.string.isRequired,
    actions: PropTypes.shape({
        fetchUserInfo: PropTypes.func.isRequired
    })
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);