import * as types from '../constants/ActionTypes';
import BASE_URL from '../utils/config';
import { fetchWithAuth, authHeaders, logout } from '../services/auth';

export const selectType = (selectedType) => ({
    type: types.SELECT_TYPE,
    selectedType
});

export const selectFile = (selectedFile) => ({
    type: types.SELECT_FILE,
    selectedFile
});

export const selectFiles = (selectedFiles) => ({
    type: types.SELECT_FILES,
    selectedFiles
});

export const resetFileSelection = () => ({
    type: types.RESET_FILE_SELECTION
});

export const cancelFileSelection = () => ({
    type: types.CANCEL_FILE_SELECTION
});

export const loadStatusStart = () => ({
    type: types.LOAD_STATUS_START
});

export const loadStatusEnd = () => ({
    type: types.LOAD_STATUS_END
});

export const addStatuses = (statuses) => ({
    type: types.ADD_STATUSES,
    statuses
});

export const addStatus = (status) => ({
    type: types.ADD_STATUS,
    status
});

export const updateStatus = (updatedStatus) => ({
    type: types.UPDATE_STATUS,
    updatedStatus
});

export const uploadProgress = (progress) => ({
    type: types.UPLOAD_PROGRESS,
    progress
});

export const uploadStart = () => ({
    type: types.UPLOAD_START
});

export const uploadEnd = () => ({
    type: types.UPLOAD_END
});

export const updateUploadingFileIndex = (uploadingFileIndex) => ({
    type: types.UPDATE_UPLOADING_FILE_INDEX,
    uploadingFileIndex
});

export const setPolling = (polling) => ({
    type: types.SET_POLLING,
    polling
});

export const updateFileConfirmationMessage = (status, message) => ({
    type: types.UPDATE_FILE_CONFIRMATION_MESSAGE,
    status,
    message
});

export const setUserInfo = (userInfo) => ({
    type: types.SET_USER_INFO,
    userInfo
});

export const fetchStatuses = () => {
    return async function (dispatch, getState) {
        dispatch(loadStatusStart());
        try {
            const response = await fetchWithAuth(BASE_URL + '/upload', {credentials: 'include'});
            const statusData = await response.json();
            const statusObj = statusData.reduce((acc, cur) => {
                acc[cur.id] = cur;
                return acc;
            }, {});
            dispatch(addStatuses(statusObj));
            dispatch(loadStatusEnd());
        }
        catch (error) {
            dispatch(loadStatusEnd());
            //dispatch a different error here
        }
    };
};

function fetchWithProgress(url, opts = {}, onProgress) {
    return new Promise((res, rej) => {
        const headers = authHeaders();
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 401) {
                logout();
            }
        };
        xhr.open(opts.method || 'get', url);
        for (const property in headers) {
            if (Object.prototype.hasOwnProperty.call(headers, property)) {
                xhr.setRequestHeader(property, headers[property]);
            }
        }
        xhr.onload = (e) => res(e.target.responseText);
        xhr.onerror = rej;
        if (opts.credentials === 'include') {
            xhr.withCredentials = true;
        }
        if (xhr.upload && onProgress) {
            xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
        }
        xhr.send(opts.body);
    });
}

const delay = (ms) => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
};

export const checkUploadedStatusUntilUploaded = async (latestStatusId) => {
    const response = await fetchWithAuth(BASE_URL + `/upload/${latestStatusId}`,  {credentials: 'include'});
    const statusData = await response.json();
    const stillUploading = statusData.status === 'uploading';
    if (stillUploading) {
        await delay(1500);
        return await checkUploadedStatusUntilUploaded(latestStatusId);
    }
    else {
        return statusData;
    }
};

export const uploadSingleFile = async (selectedFile, selectedType, dispatch) => {
    const formData = new FormData();
    formData.append('type', selectedType.value);
    formData.append('object', selectedFile, selectedFile.name);
    const response = await fetchWithProgress(BASE_URL + '/upload', {
        method: 'post',
        body: formData,
        credentials: 'include'
    }, (event) => dispatch(uploadProgress(event.loaded / event.total * 100)));
    let statusData = await JSON.parse(response);
    if (statusData.error) {
        return {
            error: statusData.error
        };
    }
    else {
        statusData = {
            ...statusData,
            dataType: selectedType.value,
            status: 'uploading'
        };
        dispatch(addStatus(statusData));
        return statusData;
    }
};

export const uploadFile = () => {
    return async function (dispatch, getState) {
        dispatch(uploadStart());
        const { selectedType } = getState().fileUpload;
        try {
            const { selectedFile } = getState().fileUpload;
            const statusData = await uploadSingleFile(selectedFile, selectedType, dispatch);
            if (statusData.error) {
                dispatch(updateFileConfirmationMessage('error', `${statusData.error}`));
            }
            else {
                dispatch(resetFileSelection());
            }
        }
        catch (error) {
            dispatch(uploadEnd());
            //display an error message to the user
        }
    };
};

export const fetchStatus = (statusId) => {
    return async function (dispatch, getState) {
        try {
            const response = await fetchWithAuth(BASE_URL + `/upload/${statusId}`, {credentials: 'include'});
            const statusData = await response.json();
            dispatch(updateStatus(statusData));
        }
        catch (error) {
            //Logger error
        }
    };
};

export const fetchUserInfo = () => {
    return async function (dispatch) {
        try {
            const response = await fetchWithAuth(BASE_URL + `/auth/userinfo`, {credentials: 'include'});
            const userInfo = await response.json();
            dispatch(setUserInfo(userInfo));
        }
        catch (error) {
            //Logger error
        }
    };
};