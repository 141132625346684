import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../actions';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import './FullScreenDropzone.css';

class FullScreenDropzone extends Component {
    constructor() {
        super();
        this.state = {
            dropzoneActive: false
        };
    }

    onDragEnter() {
        this.setState({
            dropzoneActive: true
        });
    }

    onDragLeave() {
        this.setState({
            dropzoneActive: false
        });
    }

    onDrop(files) {
        const { bulkUpload } = this.props;
        const { selectFile, selectFiles } = this.props.actions;
        if (bulkUpload && files.length > 1) {
            const selectedFiles = Array.from(files);
            selectFiles(selectedFiles);
        }
        else {
            selectFile(files[0]);
        }
        this.setState({
            dropzoneActive: false
        });
    }

    render() {
        const { dropzoneActive } = this.state;
        const { fullScreenDragDrop } = this.props;
        return (<Dropzone
            disableClick
            style={{position: 'relative', height: '100%'}}
            onDrop={this.onDrop.bind(this)}
            onDragEnter={this.onDragEnter.bind(this)}
            onDragLeave={this.onDragLeave.bind(this)}
            disabled={!fullScreenDragDrop}
        >
            { dropzoneActive && <div className="dd-overlay">
                <div className="dd-takeoff-verbiage">
                    Drop file to upload
                </div>
            </div> }
            { this.props.children }
        </Dropzone>);
    }
}

const mapStateToProps = (state) => ({
    fullScreenDragDrop: true,
    bulkUpload: false
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

FullScreenDropzone.propTypes = {
    actions: PropTypes.shape({
        selectFile: PropTypes.func.isRequired,
        selectFiles: PropTypes.func.isRequired
    }),
    fullScreenDragDrop: PropTypes.bool,
    children: PropTypes.node.isRequired,
    bulkUpload: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(FullScreenDropzone);